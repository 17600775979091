<template>
    <v-data-table :headers="headers" :items="items" class="elevation-1"
                  :options.sync="pagination" :server-items-length="totalItems"
                  :footer-props="{itemsPerPage: pagination.rowsPerPage, page: pagination.page}" dense
    :loading="loading">
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
    </v-data-table>
</template>

<script>
export default {
    name: "FDataTable",
    data() {
        return {
            items: [],
            pagination: {
                page: 1,
                rowsPerPage: 10
            },
            totalItems: 0,
            loading: false,
        }
    },
    props: ['headers', 'handler'],
    methods: {
        refresh() {
            this.loading = true;
            let page_number = this.pagination.page;
            let rowsPerPage = this.pagination.itemsPerPage;
            this.handler(page_number, rowsPerPage).then((res) => {
                this.items = res.data.data;
                this.totalItems = res.data.data.length;
                this.loading = false;
            })
        }
    },
    watch: {
        pagination: {
            handler () {
                this.refresh();
                /*this.$store.dispatch('users/getUsers',this.page)
                    .then(() => {
                        this.loading = false
                    })*/
            },
            update(){
                this.refresh();
                /*this.$store.dispatch('users/getUsers',this.page)
                    .then(() => {
                        this.loading = false
                    })*/
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
