<template>
    <div>
        <v-alert
            :value="true"
            v-for="(message, messageIdx) in messages" :key="messageIdx"
            :color="message.color"
            dense
            dismissible
            @input="(e) => { if(e.isTrusted) $store.dispatch('message/removeMessage', messageIdx) }"
        >
            {{message.text}}
        </v-alert>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ValidationMessages",
    computed: {
        ...mapGetters({
            messages: 'message/messages'
        })
    }
}
</script>

<style scoped>

</style>
