<template>
    <v-app>
    <v-main>
        <v-container>
            <v-row justify="center">
                <v-col cols="3" class="text-center">
                    <h1>AstroLife</h1>
                    <v-form @submit.prevent="login">
                        <v-card style="position:relative;">
                            <v-overlay :value="loading" absolute>
                                <v-progress-circular
                                    indeterminate
                                    size="64"
                                ></v-progress-circular>
                            </v-overlay>
                            <v-card-text>
                                <validation-messages class="text-left"></validation-messages>
                                <v-text-field prepend-icon="mdi-email" label="E-mailadres" v-model="email"></v-text-field>
                                <v-text-field type="password" prepend-icon="mdi-lock" label="Wachtwoord" v-model="password"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" type="submit">Login</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                    <v-btn text color="secondary">Wachtwoord vergeten</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
    </v-app>
</template>

<script>
import ValidationMessages from "@/components/ValidationMessages";
export default {
    name: "Login",
    components: {ValidationMessages},
    data () {
        return {
            email: '',
            password: '',
            loading: false
        }
    },
    methods: {
        async login() {
            this.loading = true;
            await this.$store.dispatch('message/clearMessages');
            try {
                await this.$store.dispatch('auth/login', {
                    email: this.email,
                    password: this.password
                });
                await this.$router.push({name: 'UserOverview'});
            } catch(e) {
                if (e.response.status === 422) {
                    if (e.response.data.error) {
                        await this.$store.dispatch('message/showMessage', {text: e.response.data.error, color: 'error'});
                    }
                }
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>

</style>
