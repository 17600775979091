<template>
    <dashboard-layout>
        <h1>{{ title }}</h1>
        <v-row>
            <v-col cols="6">
                <v-card style="position:relative;">
                    <v-overlay :value="loading" absolute>
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-form @submit.prevent="save">
                        <v-card-text>
                            <validation-messages></validation-messages>
                            <v-tabs vertical>
                                <v-tab>Algemeen</v-tab>
                                <v-tab>API toegang</v-tab>
                                <v-tab>API domeinen</v-tab>
                                <v-tab-item>

                                    <v-card>
                                        <v-card-text>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field label="Naam" prepend-icon="mdi-account" v-model="user.name"></v-text-field>
                                                    <v-text-field prepend-icon="mdi-email" label="E-mailadres" v-model="user.email"></v-text-field>
                                                    <v-checkbox label="Administrator" v-model="user.admin"></v-checkbox>
                                                    <v-text-field v-if="user.admin" :label="passwordLabel" prepend-icon="mdi-lock" v-model="user.password"></v-text-field>
                                                    <v-text-field v-if="user.admin" :label="passwordRepeatLabel" prepend-icon="mdi-lock" v-model="user.password_repeat"></v-text-field>

                                                    <v-row><v-col><h2>API Toegang</h2></v-col></v-row>
                                                    <v-row>
                                                        <v-col cols="6" style="position:relative;">
                                                            <v-overlay :value="keyLoading" absolute>
                                                                <v-progress-circular
                                                                    indeterminate
                                                                    size="64"
                                                                ></v-progress-circular>
                                                            </v-overlay>
                                                            <v-text-field v-if="isNew" label="API sleutel" value="API sleutel wordt gegenereerd na opslaan" disabled prepend-icon="mdi-api"></v-text-field>
                                                            <v-text-field v-else label="API sleutel" prepend-icon="mdi-api" append-outer-icon="mdi-refresh" @click:append-outer="changeKey" readonly :value="user.api_token" @focus="$event.target.select()"></v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row><v-col><h2>Opmerkingen</h2></v-col></v-row>
                                                    <v-row>
                                                        <v-col>
                                                            <v-textarea v-model="user.opmerkingen" rows="3"></v-textarea>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-card>
                                        <v-card-text>
                                            <v-row>
                                                <v-col v-for="(horoscoop, horoscoopIdx) in horoscopen" :key="horoscoopIdx" cols="12">
                                                    <v-row>
                                                        <v-col class="py-0" cols="3">
                                                            <p class="text--primary">
                                                                {{
                                                                    horoscoop
                                                                }}
                                                            </p>
                                                        </v-col>
                                                        <v-col class="py-0">
                                                            <!--<v-text-field class="mt-0" hide-details single-line dense label="Quota limiet" v-model="user.subscriptions[horoscoop].quota"></v-text-field>-->
                                                            <unlimited-number-input v-model="user.subscriptions[horoscoop].quota"></unlimited-number-input>
                                                        </v-col>
                                                        <v-col class="py-0">
                                                            <v-menu
                                                                ref="menu"
                                                                v-model="menu[horoscoopIdx]"
                                                                transition="scale-transition"
                                                                offset-y
                                                                :close-on-content-click="false"
                                                                min-width="auto"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field class="mt-0" hide-details single-line dense v-on="on" v-bind="attrs" v-model="user.subscriptions[horoscoop].expires" label="Vervaldatum"></v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="user.subscriptions[horoscoop].expires"
                                                                >
                                                                    <v-spacer></v-spacer>
                                                                    <v-btn
                                                                        text
                                                                        color="primary"
                                                                        @click="menu = false"
                                                                    >
                                                                        OK
                                                                    </v-btn>
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                                <v-tab-item>
                                    <v-card>
                                        <v-card-text>
                                            <h1>API Domeinen</h1>
                                            <p>
                                                Hier kunt u uw domeinen instellen waarop de API toegestaan wordt om te gebruiken.
                                                <br>
                                                <strong>Let op: Als er geen api domein is toegevoegd is er GEEN api toegang!</strong>
                                            </p>
                                            <v-text-field hide-details v-for="(domain, domainIdx) in user.api_domains" :key="domainIdx" :value="domain" readonly>
                                                <template v-slot:append-outer>
                                                    <v-btn icon @click="removeDomain(domain)">
                                                        <v-icon color="error">mdi-minus</v-icon>
                                                    </v-btn>

                                                </template>
                                            </v-text-field>

                                            <v-text-field placeholder="Domeinnaam of IP adres" hide-details v-model="newDomainField">
                                                <template v-slot:append-outer>
                                                    <v-btn icon  @click="addDomain">
                                                        <v-icon color="success" >mdi-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-text-field>
                                        </v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text>reset</v-btn>
                            <v-btn color="primary" type="submit">Opslaan</v-btn>
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

    </dashboard-layout>
</template>

<script>
import Vue from "vue";
import ValidationMessages from "@/components/ValidationMessages";
import UnlimitedNumberInput from "@/components/UnlimitedNumberInput";
const horoscopen = ['pal', 'dwm', 'chinees', 'wester', 'daghoroscoop']

export default {
    name: "UserCrud",
    components: {UnlimitedNumberInput, ValidationMessages},
    data() {
        return {
            horoscopen,
            date: [],
            menu: [],
            user: {
                id: null,
                name: "",
                email: "",
                password: "",
                password_repeat: "",
                admin: false,
                api_token: null,
                subscriptions: horoscopen.reduce((el, obj) => {

                    el[obj] = {quota: null, expires: null};
                    return el;
                }, {}),
                opmerkingen: "",
                api_domains: []
            },
            loading: false,
            keyLoading: false,
            newDomainField: "",
            test: 0
        }
    },
    computed: {
        isNew() {
            return this.user.id == null;
        },
        passwordLabel() {
            return !this.isNew ? "Nieuw Wachtwoord" : "Wachtwoord";
        },
        passwordRepeatLabel() {
            return !this.isNew ? "Herhaal nieuw wachtwoord" : "Herhaal wachtwoord";
        },
        title() {
            return this.isnew ? "Nieuwe gebruiker" : "Bewerk gebruiker";
        }
    },
    methods: {
        async fetchUser(id) {
            this.loading = true;
            let resp = await this.$axios.get(`users/${id}`);
            Vue.set(this, 'user', resp.data.data);
            this.loading = false;
        },
        async changeKey() {
            this.keyLoading = true;
            let resp = await this.$axios.post(`users/${this.user.id}/changeapikey`);
            this.user.api_token = resp.data.data.api_token;
            this.keyLoading = false;
        },
        async save() {
            this.loading = true;
            await this.$store.dispatch('message/clearMessages');
            try {
                if (!this.isNew) {
                    console.log({...this.user});
                    await this.$axios.post(`users/${this.user.id}`, {
                        ...this.user,
                        api_domains: this.user.api_domains,
                        _method: 'patch'
                    });
                } else {
                    await this.$axios.post(`users`, this.user);
                }
                await this.$store.dispatch('message/showToast', {
                    text: this.isNew ? "Gebruiker is aangemaakt" : "Gebruiker is bijgewerkt",
                    color: 'success'
                });
                await this.$router.push({name: "UserOverview"});
            } finally {
                this.loading = false;
            }
        },
        addDomain() {
            if (this.newDomainField === "") return;
            this.user.api_domains.push(this.newDomainField);
            this.newDomainField = "";
        },
        removeDomain(domain) {
            this.user.api_domains = this.user.api_domains.filter(x => x !== domain);
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.loading = true;
            this.fetchUser(this.$route.params.id).then(() => this.loading = false);
        }
    }
}
</script>

<style scoped>

</style>
