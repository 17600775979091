<template>
    <dashboard-layout>
        <f-data-table :handler="getUsers" :headers="headers" ref="table">
            <template v-slot:top>
                <v-toolbar
                    flat
                >
                    <v-toolbar-title>Gebruikers</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        :to="{name: 'NewUser'}"
                    >
                        Nieuwe gebruiker
                    </v-btn>
                </v-toolbar>
                <v-dialog
                    v-model="dialog"
                    max-width="640px"
                >
                    <v-card>
                        <v-card-title>Verwijder gebruiker</v-card-title>
                        <v-card-text>Weet je zeker dat je deze gebruiker wilt verwijderen?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dialog = false">Annuleer</v-btn>
                            <v-btn text color="primary" @click="deleteConfirm">Verwijder</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>
            <template v-slot:item.api_token="{item}">
                <span v-if="item.api_token">{{ item.api_token }}</span>
                <span v-else class="font-italic grey--text">Niet gegenereerd</span>
            </template>
            <template v-slot:item.actions="{item}">
                <v-icon small @click="$router.push({name: 'EditUser', params: { id: item.id }})">mdi-pencil</v-icon>
                <v-icon small @click="showDeleteDialog(item.id)">mdi-delete</v-icon>
            </template>
        </f-data-table>
    </dashboard-layout>
</template>

<script>
import FDataTable from "@/components/FDataTable";
export default {
    name: "Overview",
    components: {FDataTable},
    data() {
        return {
            headers: [
                {text: "id", value: "id"},
                {text: "Naam", value: "name"},
                {text: "E-mail", value: "email"},
                {text: "API Sleutel", value: "api_token"},
                {text: "Acties", value: "actions"},
            ],
            dialog: false,
            deleteUser: null
        }
    },
    methods: {
        async getUsers(page_number, rowsPerPage) {
            return await this.$axios.get(`users?page=${page_number}&rowsPerPage=${rowsPerPage}`);
        },
        async showDeleteDialog(id) {
            this.deleteUser = id;
            this.dialog = true;
        },
        async deleteConfirm() {
            try {
                await this.$axios.delete(`users/${this.deleteUser}`, {_method: 'delete'});
                await this.$store.dispatch('message/showToast', {text: "Gebruiker is verwijderd", color: "success"});
                this.$refs.table.refresh();
            } finally {
                this.dialog = false;
            }
        }
    },
    mounted() {
        this.$refs.table.refresh();
    }
}
</script>

<style scoped>

</style>
