<template>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-text-field  class="mt-0" hide-details single-line dense :value="displayValue" v-on="on" v-bind="attrs" @input="$emit('input', $event)"></v-text-field>
            </template>
            <v-list dense>
                <v-list-item link @click="$emit('input', -1)">
                    <v-list-item-title>Unlimited</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
</template>

<script>
export default {
    name: "UnlimitedNumberInput",
    props: ['value'],
    computed: {
        displayValue() {
            return this.value == -1 ? "Unlimited" : this.value;
        }
    }
}
</script>

<style scoped>

</style>
